/* src/styles/globals.css */

/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom base styles */
@layer base {
  html {
    @apply antialiased;
  }

  body {
    @apply bg-white text-gray-900;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-bold;
  }
}

/* Custom component classes */
@layer components {
  /* Button styles */
  .btn-primary {
    @apply px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 
           transition-colors duration-200 inline-flex items-center justify-center;
  }

  .btn-secondary {
    @apply px-6 py-3 bg-white text-indigo-600 border border-indigo-600 
           rounded-md hover:bg-indigo-50 transition-colors duration-200 
           inline-flex items-center justify-center;
  }

  /* Card styles */
  .card {
    @apply bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow duration-200;
  }

  /* Section styles */
  .section {
    @apply py-16 px-4;
  }

  .container-custom {
    @apply max-w-6xl mx-auto px-4;
  }
}

/* Custom utilities */
@layer utilities {
  .text-gradient {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-500;
  }

  .bg-gradient {
    @apply bg-gradient-to-r from-indigo-50 to-blue-50;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-out forwards;
}

/* For Video Background */
@layer utilities {
    .video-background {
      @apply absolute min-w-full min-h-full object-cover;
      @apply w-auto h-auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    /* For mobile devices */
    @media (max-aspect-ratio: 16/9) {
      .video-background {
        width: 100%;
        height: 100%;
      }
    }
  }